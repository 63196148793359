import React from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

// css
import "./About-us.css";

// assets
import scSecImg from "../../assets/images/STRATCAP-SECURITIES-LOGO.png";
import scImImg from "../../assets/images/StratCap-IM-Logo.png";

const Companies = () => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 0;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <section class="companies" id="companies">
      <div class="main-padding companies-container">
        <div class="main-margin companies-main-wrapper">
          <div className="main-header-margin">
            <h1 className="main-header">The StratCap Companies</h1>
          </div>
        </div>
      </div>
      <div class="main-padding companies-bottom-wrapper">
        <div class="main-margin companies-content-wrapper">
          <div class="card-grid-companies">
            <div className="grid-card">
              <div class="companies-card-inside">
                <div style={{maxWidth: "240px"}}>
                  <img style={{width: "100%"}} src={scImImg} alt="" className="companies-img" />
                </div>
                <p className="">
                  Full-service investment management organization focused on
                  digital infrastructure and technology-centric investments​
                </p>
                <NavLink
                  className="link-button"
                  to="/investment-management"
                  activeClassName="selected"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Learn More
                </NavLink>
              </div>
            </div>
            <div className="grid-card">
              <div className="companies-card-inside">
                <div style={{maxWidth: "240px"}}>
                  <img style={{width: "100%"}} src={scSecImg} alt="" className="companies-img" />
                </div>
                <p className="">
                  Registered broker/dealer and wholesale distribution
                  organization serving financial professionals and their
                  clients​
                </p>
                <NavLink
                  className="link-button"
                  to="/wholesale-distribution"
                  activeClassName="selected"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Learn More
                </NavLink>
              </div>
            </div>
            {/* <div className="grid-card">
              <div class="companies-card-inside">
                <img src={scAsImg} alt="" className="companies-img" />
                <p className="">
                  Technology and operations platform providing administrative,
                  operational and technical services​
                </p>
                <NavLink
                  className="link-button"
                  to="/technology-and-operations"
                  activeClassName="selected"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Learn More
                </NavLink>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Companies;
